(function() {
    angular.module('EntrakV5').controller('landlordProfileController', landlordProfileController);

    function landlordProfileController($scope, $rootScope, KEY, APIKEY, Service, Api, $state, $timeout) {
        console.log('landlordProfileController');

        var caller = Api.createApiCaller();
        $scope.btnStatus = {};
        $scope.users = [];
        $scope.roleLv = $rootScope.getUserAclRoleLv();

    /* first load */
        $rootScope.loadingPage = 0;
        $scope.getUsers = function(landlordId){
            $rootScope.loadingPage++;
            $scope.btnStatus.loading = true;
            caller.call(Api.getLandlordUsers(landlordId)).then(function(res){
            // caller.call(Api.getAllUsers()).then(function(res){
                $scope.users = res;
                $rootScope.loadingPage--;
                $scope.btnStatus.loading = false;
            }, function(err){
                if (err === KEY.ignore)
                    return;
                $rootScope.loadingPage--;
                $scope.btnStatus.loading = false;
                alert(Service.translate("error.generalGetDataFail"));
            });
        }
        $rootScope.getLandlordId().then($scope.getUsers);

        $rootScope.loadingPage++;
        caller.call(Api.getSetRoleOptions()).then(function(res){
          $scope.landlordRoleDropdown.setDataSource(new kendo.data.DataSource({
            data: res.roles.map(function(r) {
              return {
                text: Service.translate("label." + r),
                value: r
              }
            })
          }));
          $rootScope.loadingPage--;
        }, function(err){
            if (err === KEY.ignore)
                return;
            $rootScope.generalErrorHandler();
        });
    /* first load */

        $scope.downloadCsv = function(){
          $scope.btnStatus.loading = true;
          $rootScope.loadingPage++;
          caller.call(Api.getAllUsers(true)).then(function(res){
            var text = "";
            res = res.filter(u => u.tenantId);
            res.forEach(function(u){
              u.firstName = u.firstName.replaceAll(",", "");
              u.lastName = u.lastName.replaceAll(",", "");
            });
            res.sort(function(a, b){
              var c = a.tenantName.localeCompare(b.tenantName);
              if (c == 0)
                c = a.firstName.toLowerCase().localeCompare(b.firstName.toLowerCase());
              if (c == 0)
                c = a.lastName.toLowerCase().localeCompare(b.lastName.toLowerCase());
              return c;
            });
            res.forEach(function(u){
              text += u.email + "," + u.firstName + "," + u.lastName + "," + u.roleName + "," + u.tenantName.replaceAll(",", "") + "\n";
            });            
            var element = document.createElement('a');
            element.style.display = 'none';
            element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
            element.setAttribute('download', "users.csv");
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);

            $rootScope.loadingPage--;
            $scope.btnStatus.loading = false;
          }, function(err){
              if (err === KEY.ignore)
                  return;
              $rootScope.loadingPage--;
              $scope.btnStatus.loading = false;
              alert(Service.translate("error.generalGetDataFail"));
          });
        }

        $scope.editData = {};
        $scope.addLandlordUserWinOpt = {
            width: "740px",
            modal: true,
            draggable: false,
            visible: false,
            resizable: false,
            open: function(){
                $scope.$apply(function(){
                    $scope.btnStatus.saving = false;
                });
            }
        }
        $scope.landlordRoleDropdownOpt = {
            autoWidth: true,
            autoClose: false,
            clearButton: false,
            dataSource: [],
            dataTextField: "text",
            dataValueField: "value",
        }

        $scope.editAccount = function(user){
            if (user){
                $scope.addLandlordUserWin.title(Service.translate("profile.popup.editLandlordUser"));
                $scope.editData = {
                    id: user.id,
                    email: user.email,
                    roles: user.aclRoles,
                }
            } else {
                $scope.addLandlordUserWin.title(Service.translate("profile.popup.addLandlordUser"));
                $scope.editData = {
                    email: '',
                    roles: [APIKEY.aclRole.landlordUser],
                }
            }
            setTimeout(function(){
                $scope.addLandlordUserWin.open().center();
            });
        }
        $scope.confirmEditAccount = function(){
            $scope.btnStatus.saving = true;
            var id = $scope.editData.id;
            if (id){
                caller.call(Api.updateLandlordUser(id, $scope.editData.roles)).then(function(res){
                    $scope.addLandlordUserWin.close();
                    Service.replaceArrItem($scope.users, res);
                    $scope.btnStatus.saving = false;
                }, function(err){
                    if (err === KEY.ignore)
                        return;
                    $scope.btnStatus.saving = false;
                    alert(Service.translate("error.generalUpdateAccount"));
                });
            } else {
                var email = $scope.editData.email.trim().toLowerCase();
                caller.call(Api.createLandlordUser($rootScope.currentUser.landlordId, email, $scope.editData.roles)).then(function(res){
                    $scope.addLandlordUserWin.close();
                    $scope.users.push(res);
                    $scope.btnStatus.saving = false;
                }, function(err){
                    if (err === KEY.ignore)
                        return;
                    if (err.graphQLErrors && (err = err.graphQLErrors[0])){
                        if (err.message === 'invalid email'){
                            alert(Service.translate("error.invalidEmail"));
                        } else if (err.message === 'create profile error'){
                            alert(Service.translate("error.duplicatedEmail"));
                        } else {
                            alert(Service.translate("error.generalCreateAccount"));
                        }
                    } else {
                        alert(Service.translate("error.generalCreateAccount"));
                    }
                    $scope.btnStatus.saving = false;
                });
            }
        }

        $scope.inviteAccount = function(user){
            user.inviting = true;
            caller.call(Api.inviteUser(user.id)).then(function(res){
                user.inviteStatusV2 = res.inviteStatusV2;
                user.inviting = false;
                user.inviteSuccess = true;
                $timeout(function(){
                    delete user.inviteSuccess;
                }, 3000);
            }, function(err){
                if (err === KEY.ignore)
                    return;
                user.inviting = false;
                user.inviteSuccess = false;
                alert(Service.translate("error.generalInviteFail"));
            });
        }

        $scope.deleteAccount = function(user){
            $rootScope.deletePopup.show("profile.popup.deleteAccount", "profile.popup.deleteAccountDesc", user.email, function(){
                caller.call(Api.deleteProfile(user.id)).then(function(res){
                    Service.deleteArrItem($scope.users, user.id);
                    $rootScope.deletePopup.close();
                }, function(err){
                    if (err === KEY.ignore)
                        return;
                    alert(Service.translate("error.generalDeleteFail"));
                });
            });
        }

        $scope.$on('$destroy', function() {
            console.log("landlordProfileController destroy");
            caller.cancel();
        });
    }
})();
